<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				themecdic {{perf}}
				<DataTable :value="themedics" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
          dataKey="id" :paginator="true" :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :globalFilterFields="['theme', 'theme_en', 'theme_ko', 'idate']"
					selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="theme_ext" header="外部テーマ名ja" :sortable="true"></Column>
					<Column field="theme" header="テーマ名ja" :sortable="true"></Column>
					<Column field="theme_en" header="テーマ名en" :sortable="true"></Column>
					<Column field="theme_ko" header="テーマ名ko" :sortable="true"></Column>
          <Column field="idate" header="登録日" :sortable="true"></Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import themedicService from '@/service/ThemedicService';

export default {
	data() {
		return {
			themedics: null,
			filters1: {},
			perf: null,
		}
	},
	themedicService: null,
	created() {
		this.themedicService = new themedicService();
		this.initFilters1();
	},
	mounted() {
		this.themedicService.getAll()
			.then(d => {
				this.themedics = d.data;
				this.perf = d.perf;
			}
		);		
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		}
	}
}
</script>

<style scoped>

</style>
